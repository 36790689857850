.experience-icon {
	font-size: 300%;
	margin-top: 25%;
	text-align: center;
}

.main-badge {
	font-size: 13px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #ae944f !important;
	color: white;
	font-weight: lighter !important;
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.experience-badge {
	font-size: 11px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #f9f5e9 !important;
	color: black;
	font-weight: lighter !important;
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.vertical-timeline-element-date {
	padding: 0 !important;
	margin: 0 !important;
}

.vertical-timeline-element-title {
	color: #212529;
	font-size: 1.1rem;
}

.vertical-timeline-element-subtitle {
	color: #212529;
	font-size: 0.8rem;
}

.contact-types {
	display: flex;
	justify-content: left;
	align-items: left;
	font-size: 1.5rem;
	font-weight: 500;
	color: gray;
}

.contact-type {
	width: 100%;
	text-align: center;
	font-size: 1.1rem;
	font-weight: 500;
	color: rgb(59, 59, 59);
	text-align: left !important;
}

.contact-icon {
	font-size: 6rem;
	color: #ae944f;
}

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap");

.project-date {
    font-size: 16px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 8px 15px 2px 15px;
    position: relative;
    z-index: -500;
    border-top: 5px solid #696969;
    border-radius: 0 0 5px 5px;
    background-color: #696969;
    color: white;
}

.foto img {
    display: block;
    width: 100%;
}
.foto div {
    background: #fff;
    display: inline-block;
    margin: 0 auto 5% auto;
    padding: 10px 10px 10px;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear;
    z-index: 0;
    position: relative;
}
.foto div:after {
    color: #333;
    font-size: 25px;
    content: attr(title);
    position: relative;
    top: 15px;
}
.foto div:hover {
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    transform: scale(1.01);
    z-index: 10;
    -webkit-box-shadow: 0 10px 20px rgba(255, 255, 255, 0.7);
    -moz-box-shadow: 0 10px 20px rgba(255, 255, 255, 0.7);
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.7);
}

.foto-dark-hover div:hover {
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    transform: scale(1.01);
    z-index: 10;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
}

.project-title-settings {
    margin-top: 5%;
    font: 18px/24px "opensans-bold", sans-serif;
    letter-spacing: 1px;
    color: black;
    text-align: center;
}

.portfolio-item {
    max-width: 100%;
    margin-bottom: 15px;
    text-align: center;
}
.portfolio .portfolio-item .portfolio-item-caption {
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    transition: all ease 0.5s;
    opacity: 0;
    background-color: rgba(51, 51, 51, 0.9);
}
.portfolio .portfolio-item .portfolio-item-caption:hover {
    opacity: 1;
}
.portfolio
    .portfolio-item
    .portfolio-item-caption
    .portfolio-item-caption-content {
    font-size: 1.5rem;
}
@media (min-width: 576px) {
    .portfolio .closeButtonResponsive {
        display: block;
    }
    .portfolio .portfolio-item {
        margin-bottom: 30px;
    }
}

.project {
    width: 100%;
}

@import "./animations.css";
@import "./mixins.css";
@import "./overrides.css";
@import "./variables.css";
@import "./header.css";
@import "./projects.css";
@import "./about.css";
@import "./experience.css";

:root {
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

body {
    overflow-x: hidden;
}

#root #introduction {
    flex-direction: unset;
}

#root #introduction .introduction-container .content {
    margin: 3rem auto 2rem auto;
}

#root #introduction .introduction-container .content .title {
    color: var(--foreground-primary);
}

#root #introduction .introduction-container .columns .column .btn-resume {
    border: 2px solid white;
}

#root #introduction .introduction-container .columns .column .btn-resume:hover {
    color: white !important;
    border: 2px solid rgb(163, 163, 163);
}

#root #about {
    transform: skewY(-3deg);
    background-color: rgb(19, 19, 19);
}

#root #about .about-container {
    transform: skewY(3deg);
}
#root #about .about-container .about-description {
    color: var(--foreground-primary);
    font-size: 18px;
    font-weight: normal;
}

#root #about .about-container .tech-stack span,
#root #about .about-container .tech-stack svg,
#root #about .about-container .tech-stack i {
    display: inline-block;
    vertical-align: middle;
}

#root #about .about-container .tech-stack svg,
#root #about .about-container .tech-stack i {
    width: 36px;
    height: 36px;
    padding: 4px;
    display: inline-block;
    vertical-align: middle;
}

#root #about .about-container .tech-stack svg *,
#root #about .about-container .tech-stack i * {
    width: 36px;
    height: 36px;
}

#root #about .about-container .columns {
    padding: 12px 0px 12px 0px;
}

#root #about .about-container .columns h6 {
    color: var(--accent);
    text-align: center;
}

#root #about .about-container .columns .column {
    text-align: center;
}

#root #about .about-container .columns .column .btn-alt {
    font-weight: bold;
    background-color: transparent;
    color: var(--foreground-primary);
    border-radius: 4px;
    background-color: var(--background-primary);
    border: none !important;
    margin: 3px !important;
}

#root #about .about-container .columns .column .btn-alt:hover,
#root #about .about-container .columns .column .btn-alt:focus {
    color: var(--foreground-primary);
    transform: translateX(0.5rem);
}

#root #about .about-container h1 {
    color: inherit !important;
    font-size: 36px !important;
    border-bottom: var(--accent) solid 4px;
    display: inline-block;
}

#root #projects {
    background-color: #efe1bd;
    padding: 6rem 1.5rem;
    transform: skewY(-3deg);
}

#root #projects .projects-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: skewY(3deg);
}

#root #projects .projects-container h1 {
    color: rgba(19, 19, 19, 0.8) !important;
    font-size: 36px;
    font-weight: bold;
    align-items: center;
    margin-bottom: 1rem;
}

#root #experience {
    background-color: rgb(19, 19, 19);
    padding: 6rem 1.5rem;
}

#root #resume .experience-container h1 {
    display: flex;
    justify-content: center;
    color: white !important;
    font-size: 36px;
    font-weight: bold;
    align-items: center;
    margin-bottom: 1rem;
}

#root #contact {
    background-color: #fff;
    padding: 6rem 1.5rem;
}

#root #contact .contact-container h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(19, 19, 19, 0.8) !important;
    font-size: 36px;
    font-weight: bold;
    align-items: center;
    margin-bottom: 1rem;
}

#root footer {
    background-color: black;
}

.fadeInLeft {
    animation: fadeInLeft 1.5s ease;
}

.fadeInRight {
    animation: fadeInRight 1.5s ease;
}

.fadeInBottom {
    animation: fadeInBottom 1.5s ease;
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
